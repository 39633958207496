import React from 'react';
import './ProfileCard.css';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { logout } from '../authActions';
import { useDispatch, connect, useSelector } from 'react-redux';

const ProfileCard = ({ name, title, image,tokens}) => {

  const navigation =  useHistory()
  const dispatch = useDispatch()
  return (
    <div className="profile-card" style={{marginTop:50+"%"}}>
          <h2 style={{margin:10}}>Profile Details</h2>
      <div className="profile-image">
        <img src={image} style={{height:150,width:150,borderRadius:50+"%",objectFit:"cover",margin:20}} alt={`${name}'s profile`} />
      </div>
      <div className="profile-info">
        <h2 style={{margin:10}}>{name}</h2>
        <h4 style={{margin:10}}>{title}</h4>
        <h5>Tokens: {tokens}</h5>
        {/* <p>{description}</p> */}
        <Button color={"#7f388b"} variant="primary" style={styles.reactButton}  onClick={() => {   dispatch(logout(
      )); navigation.replace('/')}}>Logout</Button>

      </div>
    </div>
  );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '10px',
        backgroundColor: '#fff',
        height:100+"vh",
        flexDirection:"column",
        display:"flex",
        justifyContent:"flex-start",
        alignItems:"center"
    },
    logo: {
        width: '60px',
        height: '60px',
        marginBottom: '20px',
    },
    passageCard: {
        padding: '16px',
        marginBottom: '20px',
    },
    passageText: {
        textAlign: 'start',
        whiteSpace: 'pre-line',
        fontWeight: '550',
        fontSize: '18px',
        lineHeight: '1.5',
        marginTop:20
    },
    questionContainer: {
        marginTop: '20px',
    },
    questionText: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '15px',
    },
    optionButton: {
        display: 'block',
        width: '100%',
        height: 50,
        fontSize:14,
        padding: '15px',
        marginBottom: '10px',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        borderRadius: '5px',
        textAlign: 'left',
        cursor: 'pointer',
        borderWidth: 0,
        transition: 'all 0.3s ease',
    },
    selectedOption: {
        borderWidth: 3,
        borderColor: "#7f388b",
        borderStyle: 'solid',
    },
    correctOption: {
        backgroundColor: '#34C759',
        color: '#fff',
    },
    submitButton: {
        backgroundColor: '#7f388b',
        color: '#fff',
        padding: '15px',
        border: 'none',
        borderRadius: '5px',
        fontSize: '18px',
        fontWeight: 'bold',
        cursor: 'pointer',
        width: '100%',
        marginTop: '20px',
    },
    explanationBox: {
        marginTop: '20px',
        padding: '15px',
        backgroundColor: '#fff',
        borderRadius: '5px',
    },
    explanationText: {
        fontSize: '16px',
        lineHeight: '1.5',
        fontWeight: "700"
    },
    nextButton: {
        backgroundColor: '#7f388b',
        color: '#fff',
        padding: '8px',
        border: 'none',
        borderRadius: '5px',
        fontSize: '18px',
        fontWeight: 'bold',
        cursor: 'pointer',
        width: '100%',
        marginTop: '20px',
    },
    reactButton: {
        marginTop: '10px',
        width: '100%',
        backgroundColor:"#7f388b",
        fontWeight:"bold",
        color:"white"
      }
};

export default ProfileCard;