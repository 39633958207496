import React from 'react';
import './LightsaberLoader.css';

const LightsaberLoader = () => {
  return (
    <div style={styles.container}>
      <div id="loader">
        <div className="ls-particles ls-part-1"></div>
        <div className="ls-particles ls-part-2"></div>
        <div className="ls-particles ls-part-3"></div>
        <div className="ls-particles ls-part-4"></div>
        <div className="ls-particles ls-part-5"></div>
        <div className="lightsaber ls-left ls-red"></div>
        <div className="lightsaber ls-right ls-red"></div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    backgroundColor: 'black',
    minHeight: '180px',
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Helvetica, Arial, sans-serif',
  },
  header: {
    fontWeight: 200,
    fontSize: '26px',
    margin: '40px 0 15px',
  },
};

export default LightsaberLoader;
