import React, { useEffect, useRef, useState } from 'react';
import { CardColumns, Col, Row } from 'react-bootstrap';
import useMobileDetect from 'use-mobile-detect-hook';
import './App.css';
import Box from './box';
import higherImage from './images/higher.jpg';
import upsc from './images/upsc.jpg';
import school from './images/school.jpg';
import robot from './images/robot.jpg';
import { Diversity1 } from '@mui/icons-material';
import { Bounce, Fade,  Zoom } from 'react-reveal';
import Flip from 'react-reveal/Flip';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import verboace from './images/thunder.png';
import RocketComponent from './Comp/rocket-launch';
import { LoadingSpinner } from 'video-react';
import { TypeAnimation } from 'react-type-animation';
import { Slide } from "react-awesome-reveal";
import ReactPlayer from 'react-player';
import Lottie from 'react-lottie';
import diamondAnimation from './images/thunder.json';

import styled, { keyframes } from 'styled-components';

const DrawBorder = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }
  40% {
    box-shadow: 0 0 0 12px rgba(255, 0, 0, 0);
  }
  80% {
    box-shadow: 0 0 0 12px rgba(255, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }
`;

const AppStoreButton = styled.a`
  position: relative;
  display: inline-block;
  border-radius: 10px;
  margin-top: 0;
  overflow: hidden;
  border: 1px solid red;
  animation: ${DrawBorder} 3s ease-in-out infinite;

  img {
    height: 80px;
    width: 100%;
    display: block;
  }
`;

function HomeNew({scrollToRef,home,product,story,contactus,modalState}) {
  const [isNavSticky, setIsNavSticky] = useState(false);
  const detectMobile = useMobileDetect();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);  
  const [showThumbnail, setShowThumbnail] = useState(true);
  const playerRef = useRef(null);


  const handleImageLoad = () => {
    setImageLoaded(true);
  };


  const handlePlay = () => {
    setIsPlaying(true);
    setShowThumbnail(false);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (showThumbnail) {
      handlePlay();
    } else {
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    // Load main background image
    const bgImage = new Image();
    bgImage.onload = () => {
      setImageLoaded(true);
    };
    bgImage.src = 'https://storage.googleapis.com/assets_verboace/bg-hd.png';

    // Simulate loading delay (you can remove this in actual implementation)

  }, []);

    const renderPlatformSpecificContent = () => {
    if (detectMobile.isMobile()) {
      return (
        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderBottomColor:"#fff",borderWidth:0}}>
        
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
        }}>
     <Slide direction={"down"}>
              <img src={"https://storage.googleapis.com/assets_verboace/typepilot.png"} alt="Placeholder" className="responsive-image" style={{height: 450, width: "600px",marginRight:0,justifyContent:"center",alignItems:"center",display:"flex"}} />
            </Slide>
            </div>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginLeft:0,zIndex:1000}}>
            <p style={{fontSize:22,fontWeight:"bold",color:"white",fontFamily:"serif",marginTop:20}}>
             FUN TECH PRODUCTS ARE BACK
            </p>
            <p style={{fontSize:16,fontWeight:"400",color:"white",padding:16}}>
              TypePilot is a simple, light weight, fun and powerful system integrated tool for your android which turns your android smartphone into ai phone and helps you open in study-mode or summarise any url or text and proofread or rewrite any content from any app in a second.
              </p>
              <Lottie options={{ animationData: diamondAnimation }} height={40} width={40} style={{marginTop:-12}} />
              <AppStoreButton href="https://play.google.com/store/apps/details?id=com.rahuldevelops.PhiloquentWrite">
        <img src="https://storage.googleapis.com/assets_verboace/play.png" alt="Typepilot App Store" />
      </AppStoreButton>
      <button onClick={modalState} type="submit" style={{ backgroundColor: "#f70d1a", fontWeight: "bold" , width:"auto",marginTop:30}}>Watch Interactive Demo</button>
            </div>
       </div>
      );
    } else {
      return (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          width: '100vw',
          height: '100vh',
          perspective: '1000px',
          position: 'relative',
          overflow: 'hidden',
          marginTop:-150,
          marginBottom:-120
        }}>
         
            <Slide direction={"right"}>
              <img src={"https://storage.googleapis.com/assets_verboace/typepilot.png"} alt="Placeholder" className="responsive-image" style={{height: 500, width: "550px",marginRight:-20}} />
            </Slide>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginLeft:0,zIndex:1000,width:500}}>
            <p style={{fontSize:30,fontWeight:"bold",color:"white",fontFamily:"serif"}}>
             FUN TECH PRODUCTS ARE BACK
            </p>
            <p style={{fontSize:20,fontWeight:"400",color:"white",width:400,marginTop:10,marginBottom:20}}>
            TypePilot is a simple, light weight, fun and powerful system integrated tool for your android which turns your android smartphone into ai phone and helps you open in study-mode or summarise any url or text and proofread or rewrite any content from any app in a second.
                  </p>
              <Lottie options={{ animationData: diamondAnimation }} height={40} width={40} style={{marginTop:-12}} />
              {/* <a href="https://play.google.com/store/apps/details?id=com.rahuldevelops.verboaceapp" className="play-store-button" style={{ marginTop: 20 }}>
                <img src='https://storage.googleapis.com/assets_verboace/vecteezy_google-play-store-download-button-in-white-colors-download_12871364.png' style={{ height: 62, width: "auto", marginTop: 5 }} alt="Play Store" />
              </a> */}
              
               <AppStoreButton href="https://play.google.com/store/apps/details?id=com.rahuldevelops.PhiloquentWrite">
        <img src="https://storage.googleapis.com/assets_verboace/play.png" alt="Typepilot App Store" />
      </AppStoreButton>

      <button onClick={modalState} type="submit" style={{ backgroundColor: "#f70d1a", fontWeight: "bold" , width:"auto",marginTop:30}}>Watch Interactive Demo</button>
      </div>
        </div>
      );
    }
  };

  // Function to handle navbar position
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsNavSticky(true);
    } else {
      setIsNavSticky(false);
    }
  };

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Attach scroll event listener
  window.addEventListener('scroll', handleScroll);

  return (
    <div className="Landing" style={{backgroundColor:"black"}}>
   <div style={{backgroundColor:"black"}} className={`menu-icon ${isNavOpen ? 'open' : ''}`} onClick={toggleNav}>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
      </div>
      { detectMobile.isMobile() ?  <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"auto",margin:0,flexDirection:"column"}}>
      <img src={verboace} onLoad={handleImageLoad} style={{width:"auto",height:120}}></img>
        </div>:null}
      <nav style={{backgroundColor:"black"}} className={`navbar ${isNavOpen ? 'open' : ''}`} >
        <div className="nav-links" style={{marginLeft:10, fontWeight:"bold",color:"#f70d1a",alignItems:"center",justifyContent:"center",textAlign:"center"}} >
        <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#f70d1a"}} onClick={()=>{scrollToRef(home)}}>Home</Link>
          <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#f70d1a"}} onClick={()=>{scrollToRef(product)}}>Product</Link>
           <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#f70d1a"}} onClick={()=>{scrollToRef(contactus)}}>Contact Us</Link>
           <Link to="/terms-conditions" className="nav-button" style={{fontWeight:"bold",color:"#f70d1a"}}  onClick={()=>{}}>Terms</Link>
            </div>
        { detectMobile.isMobile() ? null : <div className="nav-logo" style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>  <img src={verboace} style={{width:"auto",height:120}}></img>
      </div>}
        <div className="nav-links" style={{justifyContent:"center",alignItems:"center",fontWeight:"bold",color:"#f70d1a",alignItems:"center",justifyContent:"center",textAlign:"center"}}>
      
        <Link to="/privacy-policy" className="nav-button" style={{fontWeight:"bold",color:"#f70d1a"}} onClick={()=>{}}>Privacy-Policy</Link>
     <Link to="/refund-policy" className="nav-button" style={{fontWeight:"bold",color:"#f70d1a"}}  onClick={()=>{}}>Refund-Policy</Link>
         </div>
      </nav>
     
      <div style={{marginTop:10+"%",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}} >
      <div
  style={{ 
    fontSize: detectMobile.isMobile() ? '2em' : '3em', 
    display: 'inline-block', 
    marginBottom: 20, 
    fontWeight: "bold",
    padding: 16,
    marginTop:detectMobile.isMobile()?-50: -20,
    color:"#FFF",
    textAlign: "center", 
    fontFamily:"Impact"

  }}
>
TypePilot - A superior alternative to Galaxy AI, bringing a part of seamless experience of Apple Intelligence to Android.
</div>
      </div>
      <div className="content" style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",height:"auto",marginTop:detectMobile.isMobile()?0:0+"%"}}>
        {!detectMobile.isMobile() && <div style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,
  color: 'black',
  fontSize: detectMobile.isMobile()?'1m': '2em',
  lineHeight:'71px'
}}>
  
</div>}






{!detectMobile.isMobile() ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "auto", marginTop: 20, flexDirection: "column" }}>
          {renderPlatformSpecificContent()}
        </div>
      ) : null}
<div style={{margin:0}}>
{detectMobile.isMobile() ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "auto", margin: 0, flexDirection: "column" }}>
          {renderPlatformSpecificContent()}
        </div>
      ) : null}
</div>


{/* 
<div style={{margin:20}}>



{/* <RocketComponent></RocketComponent> */}
{/* </div> */}
{/* <p style={{backgroundImage: "linear-gradient(to right, #c556c3, #f70d1a)",color:"white",fontWeight:"bold",padding:10,paddingRight:20,paddingLeft:20, borderRadius:10,marginTop:20,fontSize:detectMobile.isMobile()?15: 25,marginBottom:0}}>Invite only - Launching on Aug 1st</p>
<div style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontStyle: 'normal',
  fontWeight: 700,
  marginTop:10,
  paddingLeft:20,
  paddingRight:20,
  color:"#f70d1a"
}}>
</div> */}
<div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row", width:100+"vw"}}>
{/* <div style={{marginTop:20, width:100+"vw",display:"flex",flexDirection:detectMobile.isMobile()? "column":"row",justifyContent:"space-evenly",alignItems:"center"}}>
      <div  style={{margin:20}}>
      <Bounce>
      <Box icon={robot} text="Super Assistant" />
      </Bounce>
      </div>
      <div  style={{margin:20}}>
      <Bounce>
      <Box icon={higherImage} text="CAT/GRE/GMAT" />
      </Bounce>
      </div>
      <div  style={{margin:20}}>
      <Bounce>
      <Box icon={upsc} text="UPSC/SSC/RBI" />
      </Bounce>
      </div>
      <div  style={{margin:20}}>
      <Bounce>
      <Box icon={school} text="NCERT/JEE" />
      </Bounce>
      </div>
    </div> */}

   
    </div>
       
       
      </div>
    </div>
  );
}

export default HomeNew;



// import React, { useEffect, useState } from 'react';
// import { CardColumns, Col, Row } from 'react-bootstrap';
// import useMobileDetect from 'use-mobile-detect-hook';
// import './App.css';
// import Box from './box';
// import higherImage from './images/higher.jpg';
// import upsc from './images/upsc.jpg';
// import school from './images/school.jpg';
// import robot from './images/robot.jpg';
// import { Diversity1 } from '@mui/icons-material';
// import { Bounce, Fade,  Zoom } from 'react-reveal';
// import Slide from 'react-reveal/Slide';
// import Flip from 'react-reveal/Flip';
// import { Link } from 'react-router-dom/cjs/react-router-dom';
// import verboace from './images/verboace.png';
// import RocketComponent from './Comp/rocket-launch';
// import { LoadingSpinner } from 'video-react';


// function HomeNew({scrollToRef,home,product,story,contactus}) {
//   const [isNavSticky, setIsNavSticky] = useState(false);
//   const detectMobile = useMobileDetect();
//   const [imageLoaded, setImageLoaded] = useState(false);

//   const handleImageLoad = () => {
//     setImageLoaded(true);
//   };



//   useEffect(() => {
//     // Load main background image
//     const bgImage = new Image();
//     bgImage.onload = () => {
//       setImageLoaded(true);
//     };
//     bgImage.src = 'https://storage.googleapis.com/assets_verboace/bg-hd.png';

//     // Simulate loading delay (you can remove this in actual implementation)

//   }, []);

//   // Function to handle navbar position
//   const handleScroll = () => {
//     if (window.scrollY > 0) {
//       setIsNavSticky(true);
//     } else {
//       setIsNavSticky(false);
//     }
//   };

//   const [isNavOpen, setIsNavOpen] = useState(false);

//   const toggleNav = () => {
//     setIsNavOpen(!isNavOpen);
//   };

//   // Attach scroll event listener
//   window.addEventListener('scroll', handleScroll);

//   return (
//     <div className="Landing" style={{backgroundColor:"white"}}>
//    <div style={{backgroundColor:"white"}} className={`menu-icon ${isNavOpen ? 'open' : ''}`} onClick={toggleNav}>
//         <div className="menu-line"></div>
//         <div className="menu-line"></div>
//         <div className="menu-line"></div>
//       </div>
     
//       <nav style={{backgroundColor:"white"}} className={`navbar ${isNavOpen ? 'open' : ''}`} >
//         <div className="nav-links" style={{marginLeft:10, fontWeight:"bold",color:"#f70d1a",alignItems:"center",justifyContent:"center",textAlign:"center"}} >
//         <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#f70d1a"}} onClick={()=>{scrollToRef(home)}}>Home</Link>
//           <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#f70d1a"}} onClick={()=>{scrollToRef(product)}}>Product</Link>
//            <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#f70d1a"}} onClick={()=>{scrollToRef(contactus)}}>Contact Us</Link>
//            <Link to="/terms-conditions" className="nav-button" style={{fontWeight:"bold",color:"#f70d1a"}}  onClick={()=>{}}>Terms</Link>
//             </div>
//         { detectMobile.isMobile() ? null : <div className="nav-logo" style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>  <img src={verboace} style={{width:"auto",height:120}}></img>
//       </div>}
//         <div className="nav-links" style={{justifyContent:"center",alignItems:"center",fontWeight:"bold",color:"#f70d1a",alignItems:"center",justifyContent:"center",textAlign:"center"}}>
      
//         <Link to="/privacy-policy" className="nav-button" style={{fontWeight:"bold",color:"#f70d1a"}} onClick={()=>{}}>Privacy-Policy</Link>
//         <Link to="/refund-policy" className="nav-button" style={{fontWeight:"bold",color:"#f70d1a"}}  onClick={()=>{}}>Refund-Policy</Link>
//         </div>
//       </nav>
//       <div style={{marginTop:10+"%",display:"flex",justifyContent:"center",alignItems:"center"}} >
     
//       </div>
//       <div className="content" style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",height:"auto",marginTop:detectMobile.isMobile()?0:0+"%"}}>
//         {!detectMobile.isMobile() && <div style={{
//   fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
//   fontWeight: 900,
//   color: 'black',
//   fontSize: '2em',
//   lineHeight:'50px'
// }}>
//   <Fade>
//   Join the most Sophisticated & Innovative Tech-Enabled platform
//   </Fade>
// </div>}
// {!detectMobile.isMobile() && <div style={{
//   fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
//   fontWeight: 900,
//   color: 'black',
//   fontSize: '2em',
//   lineHeight: '50px'
// }}>
//   <Fade>
//   that assures 99%ile with 100% cashback promise
//   </Fade>
// </div>}

// {detectMobile.isMobile() ? <div style={{
//   fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
//   fontWeight: 900,
//   color: 'black',
//   fontSize: detectMobile.isMobile ? '1.8em':'3.5em',
//   padding:20
// }}>
//   Join the most Sophisticated & Innovative Tech-Enabled platform that assures 99%ile with 100% cashback promise
// </div>:null}
// {!detectMobile.isMobile() ? (
//         <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "auto", marginTop: 20, flexDirection: "column" }}>
//           {renderPlatformSpecificContent()}
//         </div>
//       ) : null}
// <div style={{margin:0}}>
// {detectMobile.isMobile() ? (
//         <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "auto", margin: 0, flexDirection: "column" }}>
//           {renderPlatformSpecificContent()}
//         </div>
//       ) : null}
// <RocketComponent></RocketComponent>
// </div>
// <p style={{backgroundImage: "linear-gradient(to right, #c556c3, #f70d1a)",color:"white",fontWeight:"bold",padding:10,paddingRight:15,paddingLeft:15, borderRadius:5,marginTop:20,fontSize:detectMobile.isMobile()?12: 18,marginBottom:0}}>GET ON THE ROCKET TO YOUR DREAM B-SCHOOL</p>
// <div style={{
//   fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
//   fontStyle: 'normal',
//   fontWeight: 700,
//   marginTop:10,
//   paddingLeft:20,
//   paddingRight:20,
//   color:"#f70d1a"
// }}>
// </div>

// <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row", width:100+"vw"}}>
// {/* <div style={{marginTop:20, width:100+"vw",display:"flex",flexDirection:detectMobile.isMobile()? "column":"row",justifyContent:"space-evenly",alignItems:"center"}}>
//       <div  style={{margin:20}}>
//       <Bounce>
//       <Box icon={robot} text="Super Assistant" />
//       </Bounce>
//       </div>
//       <div  style={{margin:20}}>
//       <Bounce>
//       <Box icon={higherImage} text="CAT/GRE/GMAT" />
//       </Bounce>
//       </div>
//       <div  style={{margin:20}}>
//       <Bounce>
//       <Box icon={upsc} text="UPSC/SSC/RBI" />
//       </Bounce>
//       </div>
//       <div  style={{margin:20}}>
//       <Bounce>
//       <Box icon={school} text="NCERT/JEE" />
//       </Bounce>
//       </div>
//     </div> */}

   
//     </div>
       
       
//       </div>
//     </div>
//   );
// }

// export default HomeNew;