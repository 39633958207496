import React from 'react';
import './Login.css'; // External CSS file for styles

const TestimonialCard = ({ name, quote, pic, source,link }) => {
  return (
    <figure className="snip1157">
        <a href={link} target='_blank'>
      <blockquote color='red' style={{color:"red",fontWeight:"bold"}}>
        {quote}
        <div className="arrow"></div>
      </blockquote>
      <img src={pic} alt={name} />
      <div className="author">
        <h5>{name} <span><br/>{source}</span></h5>
      </div>
      </a>
    </figure>
  );
};

export default TestimonialCard;
