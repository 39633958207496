import React from 'react';
import Logo from './../images/philomath_logo.png';
import '../App.css';
import HomeTop from '../TopHome';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container" >
      <Helmet>
        <title>Privacy Policy - TypePilot App</title>
        <meta name="description" content="Privacy Policy of TypePilot app." />
        <meta name="keywords" content="TypePilot, philoquent.ai, Android app, AI, encryption, privacy" />
      </Helmet>
      <HomeTop />
      <div className="privacy-policy-content" style={{ paddingTop: '16%', color: 'white', backgroundColor:"black"}}>
        <div>
          <h1>Privacy Policy</h1>
          <p>
            At TypePilot, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard the personal information you provide when using our Android app and services.
          </p>

          <h2>Information We Collect</h2>
          <ol>
            <li>
              <strong>Personal Information:</strong> When you register for our app or services, we may collect personal information such as your name, email address, and billing information.
            </li>
            <li>
              <strong>Usage Information:</strong> We may collect information about how you use our app and services, including your device information, IP address, and usage patterns.
            </li>
            <li>
              <strong>Third-Party Services:</strong> We may collect information from third-party services that you integrate with our app, such as social media platforms or payment gateways.
            </li>
          </ol>

          <h2>How We Use Your Information</h2>
          <ol>
            <li>To provide and maintain our app and services.</li>
            <li>To communicate with you about updates, offers, and promotional materials.</li>
            <li>To personalize and improve your user experience.</li>
            <li>To process payments and manage your subscription.</li>
            <li>To comply with legal obligations and enforce our policies.</li>
          </ol>

          <h2>Data Sharing and Disclosure</h2>
          <p>
            We may share your personal information with trusted third-party service providers who assist us in operating our app and services. We will not sell or rent your personal information to third parties for marketing purposes without your explicit consent.
          </p>

          <h2>Data Security</h2>
          <p>
            We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, disclosure, or misuse. However, no method of transmission over the internet or electronic storage is 100% secure.
          </p>

          <h2>Your Rights</h2>
          <p>
            You have the right to access, update, or delete your personal information stored in our systems. You may also opt out of receiving promotional communications from us at any time.
          </p>

          <h2>Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We encourage you to review this policy periodically for the latest information.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about our Privacy Policy or data practices, please contact us at <a href="mailto:rahul@philoquent.ai">rahul@philoquent.ai</a>.
          </p>

          <p>
            By using our app and services, you acknowledge that you have read and understood this Privacy Policy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
