import React from 'react';
import './App.css';
import QuizRender from './Comp/quizRender';
import PalletteRender from './Comp/palletteRender';
import { QuizProvider } from './quizContext';


function RCTest() {
  return (
    <div className="App">
        <QuizProvider>
      <div className="container">
        <div className="column column-8">
       <QuizRender></QuizRender>
        </div>
        <div className="column column-4">
       <PalletteRender></PalletteRender>
        </div>
      </div>
      </QuizProvider>
    </div>
  );
}

export default RCTest;