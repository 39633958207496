// QuizContext.js
import React, { createContext, useState, useContext } from 'react';

// Create a Context
const QuizContext = createContext();

// Create a Provider component
export const QuizProvider = ({ children }) => {
    const [duration, setDuration] = useState(0);
    const [pallette, setPallette] = useState([]);
    const [token, setToken] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [login, setLogin] = useState(false);
    const [score, setScore] = useState(0);

    return (
        <QuizContext.Provider value={{ duration, setDuration, pallette, setPallette, completed, setCompleted, score, setScore, login, setLogin, token, setToken }}>
            {children}
        </QuizContext.Provider>
    );
};

// Custom hook for using the context
export const useQuizContext = () => useContext(QuizContext);
