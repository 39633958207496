import React, { useState } from 'react';
import './App.css';
import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

function ContactForm() {
  const firebaseConfig = {
    apiKey: "AIzaSyDU1ewh0vouYZdDvoU4f_xr-KB-Lwc_gL8",
    authDomain: "philomath-web.firebaseapp.com",
    projectId: "philomath-web",
    storageBucket: "philomath-web.appspot.com",
    messagingSenderId: "750741503708",
    appId: "1:750741503708:web:b27d2abe1a2beebb957e19",
    measurementId: "G-YP22P8VPHR"
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  const firebaseApp = initializeApp(firebaseConfig);
  const db = getFirestore(firebaseApp);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data
    if (!formData.name || !formData.message) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Please fill out all fields.');
      setSnackbarOpen(true);
      return;
    }

    try {
      // Add the form data to Firestore
      setLoading(true);
      const docRef = await addDoc(collection(db, 'contacts'), {
        name: formData.name,
        email: formData.email || '', // Ensure email is optional
        message: formData.message
      });
      console.log('Document written with ID: ', docRef.id);
      setSnackbarSeverity('success');
      setSnackbarMessage('Your request has been sent. Thank you!');
      setSnackbarOpen(true);
      // Clear form fields after successful submission
      setLoading(false);
      setFormData({
        name: '',
        email: '',
        message: ''
      });
    } catch (error) {
      setLoading(false);
      console.error('Error adding document: ', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('An error occurred while sending your message. Please try again later.');
      setSnackbarOpen(true);
    }
  };

  return (
    <div className="contact-form" style={{marginTop:100}}>
      <div style={{
        fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
        fontWeight: 900,
        color: '#f70d1a',
        fontSize: '2em',
        lineHeight: '71px',
        width: '100%',
        textAlign: 'center'
      }}>
        We Love Your Reviews and Feedback
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email (Optional)</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="message">Review or Feedback</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} required />
        </div>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <CircularProgress style={{ marginLeft: '10px', color: '#f70d1a' }} size={24} />
          </div>
        ) : (
          <button type="submit" style={{ backgroundColor: "#f70d1a", fontWeight: "bold" }}>SEND</button>
        )}
      </form>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ContactForm;
