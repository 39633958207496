import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import Test from './Test';
// import Wall from './Wall';
import { QuizProvider } from './quizContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

// import Pushy from 'pushy-sdk-web';

// // Register visitor's browser for push notifications
// Pushy.register({ appId: '6697d02b4a705d186b8bb4f8' }).then(function (deviceToken) {
//     // Print device token to console
//     alert('Pushy device token: ' + deviceToken);

//     // Send the token to your backend server via an HTTP GET request
//     //fetch('https://your.api.hostname/register/device?token=' + deviceToken);

//     // Succeeded, optionally do something to alert the user
// }).then(()=>{
//   Pushy.setNotificationListener(function (data) {
//     // Print notification payload data
//     console.log('Received notification: ' + JSON.stringify(data));

//     // Attempt to extract the "message" property from the payload: {"message":"Hello World!"}
//     let message = data.message || 'Test notification';

//     // Display an alert with message sent from server
//     alert('Received notification: ' + message);
// });
// }).catch(function (err) {
//     // Notify user of failure
//     alert('Registration failed: ' + err.message);
// });

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider clientId="346911359027-nsuu9mph8v2naj8fhvpge9sfnsjar1pn.apps.googleusercontent.com">
      <Router>
        <Switch>
          {/* <Route path="/" exact component={Wall} />
          <Route path="/test" component={Test} /> */}
        </Switch>
      </Router>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;