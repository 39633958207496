import { Box, Button, Card, Snackbar, Typography } from '@mui/material';
import React, { useState,useEffect, createContext } from 'react';
import verboace from './../images/verboace.png';
import { useQuizContext } from '../quizContext';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap/esm';
import { LoadingSpinner } from 'video-react';
import { apiendpoint } from '../endpoint';

import GaugeChart from 'react-gauge-chart';
import Modal from 'react-modal';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      borderRadius: '10px',
      border: 'none',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

function QuizRender({isLoggedIn, user,token}) {


  
    const location = useLocation();
    const { data } = location.state || {};
    
    const { duration, setDuration, pallette, setPallette, completed, setCompleted, score, setScore, login, setLogin } =  useQuizContext()
      const navigation =  useHistory()
   

    useEffect(()=>{
        if(!isLoggedIn){
            navigation.replace("/")
        }
    },[isLoggedIn])
    
    const [quiz, setQuiz] = useState(null);

    useEffect(()=>{
        if(data!=null){
            console.log(data)
            setQuiz(data)
        }
    },[data])
    const [isSkimModalOpen, setIsSkimModalOpen] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState(null);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [timeSpent, setTimeSpent] = useState(null);
    // const [answered, setAnswered] = useState();
    const [totalQuizTime] = useState(15*60000); // e.g., 1 minute for the entire quiz
const [elapsedTime, setElapsedTime] = useState(0);
    const [startTime, setStartTime] = useState(Date.now());
    const [correctAnswers, setCorrectAnswers] = useState(null);
    const [loading,setLoading] = useState(false)
    const [showModal,setShowModal] = useState(false)
    const [percentile,setPercentile]=useState(80)
    const [requested, setRequested] = useState(false);


    const createRequestApi = () => {
        setLoading(true);
        return new Promise((resolve, reject) => {
            try {
                let url = apiendpoint.path + '/v1/users/requesthelp';
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        "rc_id": quiz.id
                    })
                })
                    .then(response => {
                        setLoading(false);
                        if (!response.ok) {
                            setSnackbar({ open: true, message: "Request failed! Check if tokens are available in settings screen" });
                        }
                        return response.json();
                    })
                    .then(data => {
                        if (data.success) {
                            setSnackbar({ open: true, message: "Request sent!" });
                            setRequested(true);
                        } else {
                            setSnackbar({ open: true, message: "Request failed! Check if tokens are available in settings screen" });
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        console.error('Error during fetch operation:', error);
                        reject(error);
                    });
            } catch (error) {
                setLoading(false);
                console.error('Error during request creation:', error);
                reject(error);
            }
        });
    };

    const handleRequestHelp = () => {
        if (!requested) {
            createRequestApi();
        }
    };

    const toggleSkimModal = () => {
        setIsSkimModalOpen(!isSkimModalOpen);
    };

    useEffect(()=>{
        if(quiz){
        setPallette(Array(quiz.questions.length).fill(false))
        setAnswers(Array(quiz.questions.length).fill(null))
        setTimeSpent(Array(quiz.questions.length).fill(0))
        setCorrectAnswers(Array(quiz.questions.length).fill(false))
        }
    },[quiz])



    useEffect(() => {
        if (quizCompleted) {
            // Calculate score
            let totalScore = 0;
            for (let i = 0; i < answers.length; i++) {
                if (answers[i] !== null) { // Question was answered
                    if (correctAnswers[i]) {
                        totalScore += 3; // Correct answer
                    } else {
                        totalScore -= 1; // Wrong answer
                    }
                }
            }
            setScore(totalScore);
    
            // Calculate total time spent
            const totalDuration = timeSpent.reduce((acc, time) => acc + time, 0);
            setDuration(totalDuration);
            setCompleted(true)
            // finishTestApi(totalDuration)
        }
    }, [quizCompleted, answers, correctAnswers, timeSpent]);


    const finishTestApi = async (time) => {
        let actualtime = time>900?900:time
        try {
          const url = apiendpoint.path+'/v1/users/finish'
            const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              "rc_id": quiz.id,
              "time_taken": actualtime
            })
          };
      
          const response = await fetch(url, requestOptions);
          if (response.ok) {
            // Handle success
            const data = await response.json();
  
  // Handle success
    if(data.percentilePrediction && data.percentilePrediction>80){
    setPercentile(data.percentilePrediction);
    setShowModal(true)
    }
           
          } else {
            // Handle failure
          }
        } catch (error) {
          console.error('Error finishing test:', error);
        }
      };
    

    const saveResponse = async (rcid, id, correct, res, timetaken, index) => {
        try {
            if(index==5){
                setLoading(true)
            }
          const url = apiendpoint.path+'/v1/users/save';
          ; // Assuming access token is stored in localStorage
      
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              "question_id": id,
              "rc_id": rcid,
              "response": res,
              "correct": correct,
              "timetaken": timetaken
            })
          };
      
          const response = await fetch(url, requestOptions);
          if (response.ok) {
            // Handle success
            if(index==5){
                finishTestApi(timeSpent.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
            }
          } else {
            // Handle failure
          }
          setLoading(false)
        } catch (error) {
          console.error('Error saving response:', error);
        }
      };
    
      const HelpRequestBox = ({ isHelpRequested, isLoading, handleRequestHelp }) => {
        return (
          <Box
            sx={{
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              padding: '16px',
              marginTop: '16px',
              backgroundColor: '#f9f9f9',
            }}
          >
            <Typography variant="body0" color="text.primary">
              {isHelpRequested
                ? "You have already requested help for this test. You will get a voice consultation response within 12 hours."
                : "You will get two detailed voice message from 99.9%ilers, one explaining solution and one consultation on your current progress towards 99%ile after analyzing your historical tests and performance data to explain and understand how to approach this RC."}
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              {"\n\n"}
              {"If you want to learn on your own from our app:"}
              {"\n\n"}
              {"- We offer the best classroom experience."}
              {"\n"}
              {"- Get tips from toppers on each paragraph of RC."}
              {"\n"}
              {"- We recommend trying the classroom first."}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRequestHelp}
              disabled={isLoading || isHelpRequested}
            >
              {isLoading ? 'Requesting Help...' : 'Request Help'}
            </Button>
          </Box>
        );
      };



    useEffect(() => {
        if(quiz){
        if (quizCompleted) return;
    
        // Interval for updating time spent on current question
        const questionTimer = setInterval(() => {
            setTimeSpent((prevTimeSpent) => {
                console.log(prevTimeSpent)
                const newTimeSpent = [...prevTimeSpent];
                newTimeSpent[currentQuestionIndex] += 1;
                console.log(newTimeSpent)
                return newTimeSpent;
            });
        }, 1000);
    
        // Interval for overall quiz timer
        const quizTimer = setInterval(() => {
            setElapsedTime((prevElapsedTime) => {
                if (prevElapsedTime + 1000 >= totalQuizTime) {
                    clearInterval(quizTimer);
                    setQuizCompleted(true);
                    setCurrentQuestionIndex(0);
                    finishTestApi(totalQuizTime)
                    // Reset if you want to show results or start over
                    return totalQuizTime; // Ensure the timer doesn't exceed the limit
                }
                return prevElapsedTime + 1000;
            });
        }, 1000);
    
    
        return () => {
            clearInterval(questionTimer);
            clearInterval(quizTimer);
        };
    }
    }, [currentQuestionIndex, quizCompleted, totalQuizTime,quiz]);
    useEffect(() => {
        setStartTime(Date.now());
    }, [currentQuestionIndex]);

    const handleOptionPress = (optionIndex) => {
        const newPallette = pallette
        newPallette[currentQuestionIndex] = true;
        setPallette(newPallette);

        if (!quizCompleted) {
            const updatedAnswers = [...answers];
            updatedAnswers[currentQuestionIndex] = optionIndex;
            setAnswers(updatedAnswers);
            const isCorrect = optionIndex === answerKeyToIndex(quiz.questions[currentQuestionIndex].answerKey);
            const updatedCorrectAnswers = [...correctAnswers];
            updatedCorrectAnswers[currentQuestionIndex] = isCorrect;
            setCorrectAnswers(updatedCorrectAnswers);
        }
    };


    const handleNextQuestion = () => {
        if(answers[currentQuestionIndex]==null){
            return   setSnackbar({ open: true, message: "Please select the answer" });
        }

        saveResponse(quiz.id,quiz.questions[currentQuestionIndex].id,correctAnswers[currentQuestionIndex],answers[currentQuestionIndex]==0?'A':answers[currentQuestionIndex]==1?'B':answers[currentQuestionIndex]==2?'C':'D',timeSpent[currentQuestionIndex],currentQuestionIndex)
        if (currentQuestionIndex < quiz.questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setQuizCompleted(true);
            setCurrentQuestionIndex(0);
        }
    };


    const answerKeyToIndex = (answerKey) => {
        return answerKey.charCodeAt(0) - 65; // Convert 'A' to 0, 'B' to 1, etc.
    };


    const handleNextExplanation = () => {
        if (currentQuestionIndex < quiz.questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };


    const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
    
        // Pad minutes and seconds with leading zeros if necessary
        const minutesStr = minutes.toString().padStart(2, '0');
        const secondsStr = seconds.toString().padStart(2, '0');
    
        return `${minutesStr}:${secondsStr}`;
    };
    

  

    return (
        <div style={styles.container}>
            
            <img src={verboace} style={styles.logo} alt="Verboace logo" />

            <Button 
            variant="contained" 
            color="primary" 
            onClick={toggleSkimModal} 
            style={{
                backgroundColor: "#7f388b",
                marginBottom: "20px",
                width: "100%"
            }}
        >
            Skim Through Questions
        </Button>
        
        <Modal
    isOpen={isSkimModalOpen}
    onRequestClose={toggleSkimModal}
    style={customStylesModal}
    contentLabel="Skim Through Questions"
>
    <h2 style={{color: "black", marginBottom: "20px"}}>All Questions</h2>
    <div style={{maxHeight: "70vh", overflowY: "auto"}}>
        {quiz && quiz.questions.map((question, index) => (
            <Card key={index} style={{marginBottom: "20px", padding: "15px"}}>
                <Typography variant="h6" gutterBottom>
                    {index + 1}. {question.question}
                </Typography>
                {question.options.map((option, optIndex) => (
                    <Typography key={optIndex} variant="body1" style={{marginLeft: "20px", marginTop: "10px"}}>
                        {String.fromCharCode(65 + optIndex)}. {option}
                    </Typography>
                ))}
            </Card>
        ))}
    </div>
    <Button 
        onClick={toggleSkimModal} 
        style={{
            marginTop: '20px',
            backgroundColor: "#7f388b",
            color: "white",
            fontWeight: "bold",
            width: "100%",
            padding: "10px",
            fontSize: "15px",
            borderRadius: "5px",
            border: "none"
        }}
    >
        CLOSE
    </Button>
</Modal>
            <Modal
            isOpen={showModal}
            onRequestClose={()=>{setShowModal(false)}}
            style={customStyles}
            contentLabel="Percentile Prediction"
          >
            <h2 style={{color:"black"}}>Percentile Prediction</h2>
            <GaugeChart
              id="gauge-chart"
              nrOfLevels={20}
              colors={['#EA4228', '#F5CD19', '#5BE12C']}
              arcWidth={0.3}
              animate={false}
              percent={(percentile - 80) / 20}
              arcPadding={0.02}
              formatTextValue={() => ''}
            />
               <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                The predicted percentile has been recalculated, open the app and check analytics screen to find out new exact percentile.
               </div>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <button onClick={()=>{setShowModal(false)}} style={{ marginTop: '20px', backgroundColor:"#7f388b",color:"white",fontWeight:"bold", width:100+"%",borderWidth:0,padding:6,fontSize:15,borderRadius:5 }}>CLOSE</button>
            </div>
          </Modal>
            {(quiz && answers && answers.length>0) ? <div>
            <Card elevation={10} style={styles.passageCard}>
                <p style={styles.passageText}>{quiz.passage}</p>
            </Card>

            <div style={styles.questionContainer}>
                <h3 style={styles.questionText}>
                {(currentQuestionIndex+1)}.) { quiz.questions[currentQuestionIndex].question}
                </h3>

                {quiz.questions[currentQuestionIndex].options.map((option, index) => (
                    <Card key={index} elevation={2} style={{ height: 'auto', marginTop: 10, marginBottom: 10, padding: 0, borderRadius:5 }}>
                        <button
                            style={{
                                ...styles.optionButton,
                                ...(answers[currentQuestionIndex] === index ? styles.selectedOption : {}),
                                ...(quizCompleted && index === answerKeyToIndex(quiz.questions[currentQuestionIndex].answerKey) ? styles.correctOption : {}),
                            }}
                            onClick={() => handleOptionPress(index)}
                        >
                           {option}
                        </button>
                    </Card>
                ))}

{quizCompleted ? (
                    <div>
                        <Card elevation={10} style={{ padding: 16 }}>
                            <Typography variant="body1" gutterBottom style={{fontSize:15}}>
                                <strong>Explanation:</strong> {quiz.questions[currentQuestionIndex].explanation}
                            </Typography>
                            <Typography variant="body1" gutterBottom  style={{fontSize:14,marginTop:15}}>
                                <strong>Toppers Tip:</strong> {quiz.questions[currentQuestionIndex].tip}
                            </Typography>
                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",alignItems:"center"}}>
                            <Typography variant="body1" gutterBottom style={{fontSize:16,marginTop:15}}>
                                <strong>Time taken:</strong> {formatTime(timeSpent[currentQuestionIndex])}
                            </Typography>
                            <Typography variant="body1" gutterBottom style={{fontSize:16,marginTop:15}}>
                                <strong>Toppers average time:</strong> {formatTime(quiz.questions[currentQuestionIndex].avg_time_taken)}
                            </Typography>
                            </div>
                        </Card>
                        {currentQuestionIndex < quiz.questions.length - 1 ? (
                            <Button style={{marginTop:20,backgroundColor:"#7f388b"}} variant="contained" color="primary" onClick={handleNextExplanation}>
                                Next Explanation
                            </Button>
                        ):
                        <Button style={{marginTop:20,backgroundColor:"#7f388b"}} variant="contained" color="primary" onClick={()=>{navigation.replace('/home')}}>
                                Go Home
                            </Button>
                        }
                    </div>
                ) : (
                    <Button disabled={loading} style={{marginTop:20,backgroundColor:"#7f388b"}} variant="contained" color="primary" onClick={handleNextQuestion}>
                        {loading?"Loading...": currentQuestionIndex < quiz.questions.length - 1 ? 'Next Question' : 'Finish Quiz'}
                    </Button>
                )}
                </div>
            </div>:<div>
                Loading please wait...
               {/* <LoadingSpinner/> */}
                </div>}

           {quizCompleted && <HelpRequestBox isHelpRequested={requested} isLoading={loading} handleRequestHelp={handleRequestHelp}></HelpRequestBox>}

                <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
      />
        </div>
    );
}

const customStylesModal = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        borderRadius: '10px',
        border: 'none',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        maxWidth: '80%',
        maxHeight: '80%',
        overflow: 'auto'
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '10px',
        backgroundColor: '#fff',
    },
    logo: {
        width: 'auto',
        height: '60px',
        marginBottom: '20px',
    },
    passageCard: {
        padding: '16px',
        marginBottom: '20px',
    },
    passageText: {
        textAlign: 'start',
        whiteSpace: 'pre-line',
        fontWeight: '550',
        fontSize: '20px',
        lineHeight: '2',
        marginTop:20
    },
    questionContainer: {
        marginTop: '20px',
    },
    questionText: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '15px',
    },
    optionButton: {
        display: 'block',
        width: '100%',
        height: 'auto',
        fontSize:16,
        padding: '15px',
        marginBottom: '10px',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        borderRadius: '5px',
        textAlign: 'left',
        cursor: 'pointer',
        borderWidth: 0,
        transition: 'all 0.3s ease',
    },
    selectedOption: {
        borderWidth: 3,
        borderColor: "#7f388b",
        borderStyle: 'solid',
    },
    correctOption: {
        backgroundColor: '#34C759',
        color: '#fff',
    },
    submitButton: {
        backgroundColor: '#7f388b',
        color: '#fff',
        padding: '15px',
        border: 'none',
        borderRadius: '5px',
        fontSize: '18px',
        fontWeight: 'bold',
        cursor: 'pointer',
        width: '100%',
        marginTop: '20px',
    },
    explanationBox: {
        marginTop: '20px',
        padding: '15px',
        backgroundColor: '#fff',
        borderRadius: '5px',
    },
    explanationText: {
        fontSize: '16px',
        lineHeight: '1.5',
        fontWeight: "700"
    },
    nextButton: {
        backgroundColor: '#7f388b',
        color: '#fff',
        padding: '8px',
        border: 'none',
        borderRadius: '5px',
        fontSize: '18px',
        fontWeight: 'bold',
        cursor: 'pointer',
        width: '100%',
        marginTop: '20px',
    },
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
    token:state.auth.token
});

export default connect(mapStateToProps)(QuizRender);