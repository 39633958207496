import { Button, Card, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import verboace from './../images/verboace.png';
import { useQuizContext } from '../quizContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { connect, useDispatch } from 'react-redux';
import '../App.css';
import { apiendpoint } from '../endpoint';
import axios from 'axios';
import MyCard from './myCard';
import ProfileCard from './profileCard';
import ContentLoader from 'react-content-loader';
import { logout } from '../authActions';
import ConversationModal from './ConversationModal';



function Dashboard({ isLoggedIn, user, token }) {
    const { duration, setDuration, pallette, setPallette, completed, setCompleted, score, setScore, login, setLogin } = useQuizContext();
    const navigation = useHistory();
    const [data, setData] = useState(null);
    const [dataTest, setDataTest] = useState(null);
    const [loading, setLoading] = useState(true);
    const [testloading, settestloading] = useState(false);
    const [modal, setModal] = useState(false);
    const [weakArea, setWeakArea] = useState(null);
    const [weakCategory, setweakCategory] = useState(null);
    const dispatch = useDispatch()

    useEffect(() => {
        if (!isLoggedIn) {
            navigation.replace("/");
        }
    }, [isLoggedIn]);

    const fetchTest = async () => {
        try {
            settestloading(true)
            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            };

            const postData = {
                //  rcId:"bf643512-a8a8-4fb1-a197-b5d4ec0074a6"
            };

            const response = await axios.post(apiendpoint.path + "/v1/users/getfreshrcweb", postData, { headers });
            console.log(response);
            setDataTest(response.data.rcDetails)
            if(response.data.weakAreamain==null||response.data.weakCategorymain==null){
                navigation.push('/testing',{data:response.data.rcDetails})
            }
            else{
                setWeakArea(response.data.weakAreamain)
                setweakCategory(response.data.weakCategorymain)
                setModal(true)
            }
            // navigation.push('/testing',{data:response.data.rcDetails})
            settestloading(false);
        } catch (error) {
            dispatch(logout(
            )); 
            navigation.replace('/')
            console.error('Error fetching posts:', error);
            settestloading(false);
        }
    };

    // useEffect(()=>{
    //     if(weakArea!=null && weakCategory!=null){
       
    //     }
    // },[weakArea,weakCategory])


    const onClose = async()=>{
        setModal(false)
        navigation.push('/testing',{data:dataTest})
    }

    const fetchPosts = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            };

            const postData = {
                page: 1,
                limit: 25,
                onlyMine: false,
                bookmarks: false
            };

            const response = await axios.post(apiendpoint.path + "/v1/users/posts", postData, { headers });
            setData(response.data.posts);
            setLoading(false);
        } catch (error) {
            dispatch(logout(
            )); 
            navigation.replace('/')
            console.error('Error fetching posts:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log(token);
        fetchPosts();
    }, []);

    const ShimmerLoader = () => (
        <ContentLoader
            speed={2}
            width={800}
            height={200}
            viewBox="0 0 400 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="5" ry="5" width="800" height="200"  style={{margin:10}}/>
        </ContentLoader>
    );


    

    return (
        <div className="App">
            <div className="container">
                <div className="fixed-header" style={styles.fixedHeader}>
                    <img src={verboace} style={styles.logo} alt="Verboace logo" />
                    {user && user.verified &&  <Button disabled={testloading} color={"#7f388b"} variant="primary" style={styles.reactButton} onClick={() => {navigation.push('/admin')}}>{"Admin"}</Button>}

                   {/* {user && user.verified && <Button disabled={testloading} color={"#7f388b"} variant="primary" style={styles.reactButton} onClick={() => {navigation.navigate('/admin')}}>{testloading?"Loading...":"Admin View"}</Button>} */}
                    <Button disabled={testloading} color={"#7f388b"} variant="primary" style={styles.reactButton} onClick={() => {fetchTest()}}>{testloading?"Loading...":"Take the test"}</Button>
                </div>
               
                <div className="column column-8" style={{ flexDirection: "column", display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 150 }}>
                    {loading ? (
                        <div>
                        <ShimmerLoader />
                        <ShimmerLoader />
                        <ShimmerLoader />
                        <ShimmerLoader />
                        <ShimmerLoader />
                        <ShimmerLoader />
                        </div>
                    ) : (
                        data && data.length > 0 ? (
                            data.map((post, index) => (
                                <MyCard key={index} data={post} token={token} />
                            ))
                        ) : (
                            <Typography variant="h6">No posts available</Typography>
                        )
                    )}
                </div>
                <div className="column column-4">
                    {user && <ProfileCard name={user.display_name} image={user.display_pic} title={user.email} tokens={user.tokens} />}
                </div>
            </div>
            <ConversationModal
        isOpen={modal}
        onClose={onClose}
        weakArea={weakArea}
        weakCategory={weakCategory}
      />
        </div>
    );
}

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '10px',
        backgroundColor: '#fff',
        height: '100vh',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    fixedHeader: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#fff',
        zIndex: 1000,
        display: 'flex',
        marginLeft: -175,
        justifyContent: 'center',
        flexDirection: "column",
        alignItems: 'center',
        padding: '10px 20px',
    },
    logo: {
        width: '60px',
        height: '60px',
        marginBottom: '20px',
    },
    passageCard: {
        padding: '16px',
        marginBottom: '20px',
    },
    passageText: {
        textAlign: 'start',
        whiteSpace: 'pre-line',
        fontWeight: '550',
        fontSize: '18px',
        lineHeight: '1.5',
        marginTop: 20
    },
    questionContainer: {
        marginTop: '20px',
    },
    questionText: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '15px',
    },
    optionButton: {
        display: 'block',
        width: '100%',
        height: 50,
        fontSize: 14,
        padding: '15px',
        marginBottom: '10px',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        borderRadius: '5px',
        textAlign: 'left',
        cursor: 'pointer',
        borderWidth: 0,
        transition: 'all 0.3s ease',
    },
    selectedOption: {
        borderWidth: 3,
        borderColor: "#7f388b",
        borderStyle: 'solid',
    },
    correctOption: {
        backgroundColor: '#34C759',
        color: '#fff',
    },
    submitButton: {
        backgroundColor: '#7f388b',
        color: '#fff',
        padding: '15px',
        border: 'none',
        borderRadius: '5px',
        fontSize: '18px',
        fontWeight: 'bold',
        cursor: 'pointer',
        width: '100%',
        marginTop: '20px',
    },
    explanationBox: {
        marginTop: '20px',
        padding: '15px',
        backgroundColor: '#fff',
        borderRadius: '5px',
    },
    explanationText: {
        fontSize: '16px',
        lineHeight: '1.5',
        fontWeight: "700"
    },
    nextButton: {
        backgroundColor: '#7f388b',
        color: '#fff',
        padding: '8px',
        border: 'none',
        borderRadius: '5px',
        fontSize: '18px',
        fontWeight: 'bold',
        cursor: 'pointer',
        width: '100%',
        marginTop: '20px',
    },
    reactButton: {
        marginTop: '10px',
        width: 600,
        backgroundColor: "#7f388b",
        fontWeight: "bold",
        padding: 4,
        color: "white",
        fontSize: 16
    }
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
    token: state.auth.token
});

export default connect(mapStateToProps)(Dashboard);
