import zIndex from '@mui/material/styles/zIndex';
import { height } from '@mui/system';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

const VideoPopupModal = ({state,statemodal}) => {
  const [isOpen, setIsOpen] = useState(state);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const openModal = () => {
    setIsOpen(true);
  };

  useEffect(()=>{
    setIsOpen(state)
  },[state])

  const closeModal = () => {
    setIsOpen(false);
    statemodal()
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
     <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            background: '#000',
            color: '#fff',
            overflow: 'auto',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            width: windowWidth > 600 ? '80%' : '90%',
            maxWidth: '800px',
            maxHeight: windowHeight * 0.9, // Ensure it fits within 90% of the viewport height
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height:windowWidth>600 ? 600:500,
            zIndex:1000
          },
        }}
      >
        <h2 style={{ marginBottom: '10px' }}>Interactive Demo of Typepilot.app</h2>
        <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%', height: 0 }}>
          <iframe
            title="Typepilot Interactive Demo"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: windowWidth > 600 ? '400px' : '200px',
              borderRadius: '4px',
            }}
            src={`https://www.youtube.com/embed/ZdB4owV-QoA${isOpen ? '?autoplay=1' : 'autoplay=0'}`} // Add autoplay when modal is open
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        
          ></iframe>
        </div>
        <button
          onClick={closeModal}
          style={{
            backgroundColor: 'red',
            color: '#fff',
            border: 'none',
            padding: '6px 20px',
            borderRadius: '4px',
            marginTop: '20px',
          }}
        >
          CLOSE
        </button>
      </Modal>
    </div>
  );
};

export default VideoPopupModal;