import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiendpoint } from './endpoint';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { Pagination } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ff0000',
    },
  },
});

const UserTable = ({ isLoggedIn, user, token }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigation = useHistory();
  const [totalPages, setTotalPages] = useState(0);
  const [totalusers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState('');
  const [subscribedcount, setsubcount] = useState(0);
  const [trialcount, settrialcount] = useState(0);
  const [notcount, setnotcount] = useState(0);
  
  const usersPerPage = 10;

  useEffect(() => {
    if (!isLoggedIn) {
      navigation.replace("/");
    }
  }, [isLoggedIn, user, navigation]);

  useEffect(() => {
    fetchUsers(currentPage, search);
  }, [currentPage, search, token]);

  const fetchUsers = async (page, search) => {
    try {
      setLoading(true);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(`${apiendpoint.path}/v1/auth/adminusers`, {
        page: page + 1,
        limit: usersPerPage,
        search,
      }, { headers });
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
      setTotalUsers(response.data.total);
      setsubcount(response.data.subscribedCount);
      settrialcount(response.data.trialCount);
      setnotcount(response.data.subscribedNotTrialCount);
    } catch (error) {
      console.error('Error fetching users:', error);
      navigation.replace("/");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0);
  };

  const emailUser = (email) => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Container>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
          <img src="https://storage.googleapis.com/assets_verboace/thunder.png" style={{ height: 100, width: 100 }} alt="Verboace" className="logo" />
        </div>
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",alignItems:"center"}}>
      <div style={{ margin: 10, fontWeight: "bold", fontSize: 20 }}>
        Total Users: {totalusers}
      </div>
      <div style={{ margin: 10, fontWeight: "bold", fontSize: 20 }}>
        Subscribers count: {subscribedcount}
      </div>
      <div style={{ margin: 10, fontWeight: "bold", fontSize: 20 }}>
        Trial count: {trialcount}
      </div>
      <div style={{ margin: 10, fontWeight: "bold", fontSize: 20 }}>
        No sub no trial count: {notcount}
      </div>
      </div>
        <SearchInput
          type="text"
          placeholder="Search by name, display picture or email"
          value={search}
          onChange={handleSearchChange}
        />
        {loading ? (
          <LoaderWrapper>
            <ClipLoader color="#ff0000" loading={loading} size={150} />
          </LoaderWrapper>
        ) : (
          <>
            <TableWrapper>
              <Table>
                <TableHeader>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Subscribed</th>
                    <th>Verified</th>
                    <th>Trial</th>
                    <th>Last login At</th>
                    <th>Action</th>
                  </tr>
                </TableHeader>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.email}>
                      <td>{user.display_name}</td>
                      <td>{user.email}</td>
                      <td>{user.subscribed ? 'Yes' : 'No'}</td>
                      <td>{user.verified ? 'Yes' : 'No'}</td>
                      <td>{user.trial ? 'Yes' : 'No'}</td>
                      <td>{new Date(user.updated_at).toLocaleString()}</td>
                      <td>
                        <ActionButton onClick={() => emailUser(user.email)}>Email User</ActionButton>
                      </td>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
            <PaginationWrapper>
              <Pagination
                count={totalPages}
                page={currentPage + 1}
                onChange={handlePageChange}
                color="primary"
              />
            </PaginationWrapper>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  min-height: 100vh;
  background-color: #121212;
  color: #ffffff;
`;

const TotalUsers = styled.div`
  margin: 10px 0;
  font-weight: bold;
  font-size: 20px;
  color: #ff0000;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #333;
  border-radius: 4px;
  font-size: 16px;
  background-color: #1e1e1e;
  color: #ffffff;

  &::placeholder {
    color: #888;
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #1e1e1e;
`;

const TableHeader = styled.thead`
  background-color: #2c2c2c;

  th {
    padding: 12px;
    text-align: left;
    font-weight: bold;
    color: #ff0000;
  }
`;

const TableBody = styled.tbody`
  tr:nth-child(even) {
    background-color: #252525;
  }
`;

const TableRow = styled.tr`
  td {
    padding: 12px;
    border-bottom: 1px solid #333;
  }
`;

const DisplayPic = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const ActionButton = styled.button`
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #cc0000;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
  token: state.auth.token
});

export default connect(mapStateToProps)(UserTable);