import React, { useState, useEffect } from 'react';
import { Button, Form, Alert, Spinner } from 'react-bootstrap';
import { Snackbar } from '@mui/material';
import axios from 'axios';
import './Login.css';
import { encryptData } from './encryption';
import { useHistory } from 'react-router-dom';
import { useDispatch, connect, useSelector } from 'react-redux';
import { loginSuccess } from '../authActions';
import { apiendpoint } from '../endpoint';

const LoginComponent = ({ isLoggedIn }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const navigate = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 8;
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (navigate.push) {
        navigate.replace('/admin');
      }
    }
  }, [isLoggedIn, navigate]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long.');
      return;
    }
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(apiendpoint.path + '/v1/auth/adminlogin', { email, password });
      const { access } = response.data;
      
      // Store the access token
      localStorage.setItem('accessToken', access);
      
      // Dispatch login success action
      dispatch(loginSuccess(access, { email })); // We don't have user data, so we're just passing email

      // Navigate to home page
      navigate.replace('/admin');
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to login. Please check your credentials and try again.' });
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="header">
          <img src="https://storage.googleapis.com/assets_verboace/thunder.png" style={{ height: 100, width: 100 }} alt="Verboace" className="logo" />
          <div style={{ fontSize: 20, fontWeight: "bold", marginBottom: 50, color:"white" }}>Admin login</div>
        </div>

        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleLoginSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label style={{color:"white"}}>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label style={{color:"white"}}>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={loading} className="submit-button" style={{backgroundColor:"red"}}>
            {loading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mr-2" />
                Logging in...
              </>
            ) : (
              'Login'
            )}
          </Button>
        </Form>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user
});

export default connect(mapStateToProps)(LoginComponent);